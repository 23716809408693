<template>
  <div class="corpusdetaillist-msg">
    <van-nav-bar class="van-nav-bar--fixed" title="" left-arrow  right-text="开奖记录"  @click-left="onClickLeft"  @click-right="onClickRight"></van-nav-bar>
    <div class="vant-swiper-wrap">
        <div class="van-swipe">
            <div class="van-swipe__track" style="transition-duration: 0ms; transform: translateX(0px); width: 480px;">
                <div class="van-swipe-item" style="width: 480px;"><img
                        src="/img/20220403200039--1715100889.jpg">
                </div>
            </div>
        </div><i class="close van-icon van-icon-clear" style="color: rgb(255, 255, 255); font-size: 16px;">
    
        </i>
    </div>


    <div class="van-pull-refresh">

        
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
                 <div class="list-item van-cell" v-for="item in list" :key="item.id">
                        <div class="van-cell__value van-cell__value--alone">
                            <div class="cell-top clearfix">
                                <div class="cell-top-img" v-if="item.avatar"><img
                                        :src="item.avatar"></div>
                                <div class="cell-top-text">
                                    <p>
                                        {{ item.username }}
                                    </p>
                                    <div>{{ item.date }}</div>
                                </div>
                            </div>
                            <div class="content-box has-read" @click="goToUrl(item.id)">
                                <p>#{{ item.id }} {{ item.title }}</p>
                                <!--<p v-if="item.smallimage"><van-image :src="siteurl+item.smallimage"/></p>-->
                            </div>
                            <!-- 
                            <div class="operation-box clearfix">
                                <div class="good"><i class="van-icon van-icon-good-job" style="font-size: 17px;">
                             
                                    </i>431
                                </div>
                                <div class="comment"><i class="van-icon van-icon-chat" style="font-size: 17px;">
                              
                                    </i>22
                                </div>
                            </div>
                            -->
                        </div>
                    </div>

        </van-list>


        </div>

  </div>
</template>

<script>
import { Toast } from 'vant'

export default {
  data() {
    return {
      category_id:1,
      year_id:1,
      lotto_id:2,
      active: 'picture',
      list:[],
      loading: false,
      finished: false,
      iserror:false,
      refreshing:false,
      page:0,
      message:'',
      value:'',
      iscolour: 0,
    };
  },
  mounted() {
        //this.active='picture';
        this.lotto_id= parseInt(this.$route.query.lotto_id) ? parseInt(this.$route.query.lotto_id)  : 2;
        this.category_id= parseInt(this.$route.query.id) ? parseInt(this.$route.query.id)  : 1;
    },
  methods: {
        onClickLeft() {
            // 返回上一级页面
            this.$router.back();
        },
        onClickRight() {
            this.$router.push({ path: '/kaijiang'});
        },
        goToUrl(id){
            this.$router.push({ path: '/corpus/show?id='+id+'&lotto_id='+this.lotto_id});
        },
         onLoad() {
           this.page++;
            this.getData();
        },
        getData() {
            Toast.loading({
            message: '加载中...',
            forbidClick: true,
            });
            setTimeout(() => {

                 this.$http
                    .get("api/corpus/index?category_id="+this.category_id+"&page="+this.page+'&lotto_id='+this.lotto_id)
                    .then(function (response) {
                        
                        if (response.data.code == "ok") {

                            if(response.data.data.list.data.length<10){
                                this.finished = true;
                            }else{
                                this.finished = false;
                            }
                            
                            if(!response.data.data.list.data.length){
                                this.finished = true;
                            }else{
                                for (let i = 0; i < response.data.data.list.data.length; i++) {
                                    this.list.push(response.data.data.list.data[i]);
                                }
                            }

                            this.loading = false;
                           // 数据全部加载完成
                            if (this.list.length >= response.data.data.list.total) {
                                 this.finished = true;
                            }
                            //this.finished = true;
                        } else {
                            
                            this.iserror = true;
                            this.message = response.data.msg;
                        }
                        this.loading = false;
                        Toast.clear();
                       // console.log(this.list);
                    });
            }, 500);
          
        },
    }
}
</script>
<style>
.corpusdetaillist-msg .vant-swiper-wrap {
    margin-top: 50px;
}
.corpus-wrap .search-list {
    margin-bottom: 50px;
}
.van-pull-refresh {
    margin-bottom: 50px;
}
 
</style>